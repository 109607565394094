<!--  -->
<template>
  <div class='kaoshi round bj-col-white pad' v-loading="loading" :element-loading-text="loadingText">
    <h3>药师考核</h3>
    <div class="msg">
      <h4>{{info.xuhao}}、【{{info.tixing}}题】</h4>
      <div class="tishu">{{info.xuhao}}/{{info.tishu}}</div>
    </div>
    <p>{{info.mingcheng}}</p>
    <div class="xuanze">
      <div v-for="(item, index) in info.answer" :key="index" @click="onChange(index)">
        <div :class="{'xuanzhong': item.checked}">{{item.label}}</div>
        {{item.title}}
      </div>
    </div>
    <div class="btn">
      <template v-if="info.xuhao != info.tishu">
        <el-button round plain style="width: 120px" size="medium" type="info" disabled v-if="!status">下一题</el-button>
        <el-button round plain style="width: 120px" size="medium" type="success" @click="handleNext()" v-else>下一题</el-button>
      </template>
      <template v-else>
        <el-button round style="width: 120px" size="mini" type="info" disabled v-if="!status">提交考试</el-button>
       <el-button round style="width: 120px" size="mini" type="success" @click="submitRoll()" v-else>提交考试</el-button>
      </template>
    </div>

    <video-view v-model="show" type="cszp" :id="id"></video-view>

    <div class="popup" v-if="visible">
      <div>
        <template v-if="score == 100">
          <img src="../assets/img/success.png" alt="" class="pass">
          <div class="hint pass">恭喜您！考试合格。</div>
        </template>
        <template v-else>
          <img src="../assets/img/error.png" alt="" class="fail">
          <div class="hint fail">很遗憾！考试未通过，请重新考试。</div>
        </template>
        <div class="grade">
          成绩&nbsp;&nbsp;<span :style="{color: score == 100 ? '#00a300' : 'red'}">{{score}}</span>&nbsp;&nbsp;分
        </div>
        <div class="answer">
          正确答案
          <div v-for="(item, index) in answer" :key="index">
            {{index+1}}：{{item}}
          </div>
        </div>
        <el-button style="width: 120px" :type="score == 100 ? 'success' : 'danger'" size="medium" @click="handleQuit">确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '../api/study'
import VideoView from './components/video/index.vue'

export default {
  components: {
    VideoView
  },
  data() {
    return {
      id: '',
      loading: false,
      loadingText: '',
      xuhao: 1,
      status: false,
      info: {},
      show: false,
      score: 60,
      visible: false,
      answer: [],
      allover: 0
    };
  },
  computed: {},
  filters: {
    
  },
  watch: {},
  created() {
    this.id = this.$route.query.id
    this.getTopic()
  },
  mounted() {
    this.init()
  },
  beforeDestroy () {
    document.onkeydown = () => {}
  },
  //方法集合
  methods: {
    // 阻止页面刷新
    init () {
      document.onkeydown = (e) => {
        var evt = window.event || e;
        var k = evt.keyCode || evt.which;
        //屏蔽ctrl+R，F5键，ctrl+F5键  F3键！验证
        if (
          (e.ctrlKey == true && k == 82) ||
          k == 116 ||
          (e.ctrlKey == true && k == 116) ||
          k == 114
        ) {
          this.$message.warning("您的操作会导致本页面重置，考试将重新进行，请谨慎操作！")
          if (evt.preventDefault) {
            evt.preventDefault();
          } else {
            evt.keyCode = 0;
            evt.returnValue = false
          }
        }
        if (k == 8) {
          this.$message.warning("您的操作会导致本页面重置，考试将重新进行，请谨慎操作！")
          if (evt.preventDefault) {
            evt.preventDefault();
          } else {
            evt.keyCode = 0;
            evt.returnValue = false
          }
        }
        //屏蔽 Ctrl+n   验证可以实现效果
        if (e.ctrlKey && k == 78) {
          this.$message.warning("您的操作会导致本页面重置，考试将重新进行，请谨慎操作！")
          if (evt.preventDefault) {
            evt.preventDefault();
          } else {
            evt.keyCode = 0;
            evt.returnValue = false
          }
        }
        //屏蔽 Alt+ 方向键 ← ;屏蔽 Alt+ 方向键 → ！验证
        if (e.altKey && (k == 37 || k == 39)) {
          this.$message.warning("您的操作会导致本页面重置，考试将重新进行，请谨慎操作！")
          if (evt.preventDefault) {
            evt.preventDefault();
          } else {
            evt.keyCode = 0;
            evt.returnValue = false
          }
        }
      };
    },
    // 获取题目信息
    getTopic () {
      this.loading = true
      Api.getEaxmArr({
        exam_id: this.id,
        xuhao: this.xuhao
      }).then(res => {
        this.loading = false
        let info = res.data
        if (info.tixing == '判断') {
          info.answer = [info.answer[0], info.answer[1]]
        }
        for (let i in info.answer) {
          let item = info.answer[i]
          item.label = item.title.split(":")[0]
          item.title = item.title.split(":")[1]
          info.answer[i] = item
        }
        this.answer.push(info.true_answer)
        this.info = info
        if (info.is_zhuapai == 1) {
          this.show = true
        }
      })
    },
    // 选择答案
    onChange (idx) {
      let num = 0
      let info = this.info
      info.answer[idx].checked = !info.answer[idx].checked
      if (info.tixing !== '多选') {
        for (let i in info.answer) {
          if (Number(i) != idx) {
            info.answer[i].checked = false
          }
          if (info.answer[i].checked) num++
        }
        if (num == 0) {
          this.status = false
        } else {
          this.status = true
        }
      } else {
        for (let i in info.answer) {
          if (info.answer[i].checked) num++
        }
        if (num > 1) {
          this.status = true
        } else {
          this.status = false
        }
      }
      this.info = info
    },
    // 获取下一题
    handleNext () {
      let xuhao = this.xuhao
      xuhao++
      this.xuhao = xuhao
      this.status = false
      this.answerFun()
      this.getTopic()
    },
    // 提交本题答案
    answerFun () {
      let answer = ''
      let is_zhengque = 2
      let info = this.info
      for (let i in info.answer) {
        if (info.answer[i].checked) {
          answer += info.answer[i].label
        }
      }
      if (answer == info.true_answer) {
        is_zhengque = 1
      }
      Api.examOver({
        exam_id: this.id,
        xuhao: this.xuhao-1,
        is_zhengque: is_zhengque,
        answer: answer
      })
    },
    // 交卷
    submitRoll () {
      this.loading = true
      this.loadingText = '正在核验'
      let answer = ''
      let is_zhengque = 2
      let info = this.info
      for (let i in info.answer) {
        if (info.answer[i].checked) {
          answer += info.answer[i].label
        }
      }
      if (answer == info.true_answer) {
        is_zhengque = 1
      }
      // 提交本题答案
      Api.examOver({
        exam_id: this.id,
        xuhao: this.xuhao,
        is_zhengque: is_zhengque,
        answer: answer
      }).then(() => {
        // 结束考试
        Api.getExamRes({
          exam_id: this.id,
        }).then((res) => {
          this.loading = false
          this.visible = true
          this.score = res.data.fen
          this.allover = res.data.allover
        })
      })
    },
    handleQuit () {
      if (this.score == 100 && this.allover == 1) {
        this.$confirm('祝贺您已完成专业课程学习与考试，学完考完即可查询学分，进学习平台或“广东省执业药师管理系统”查询学分。', '温馨提示', {
          confirmButtonText: '确定',
          showCancelButton: false,
          type: 'info',
          center: true
        }).then(() => {
          this.visible = false
          this.$router.go(-1)
        }).catch(() => {})
        return;
      } else{
        this.visible = false
        this.$router.go(-1)
      }
    }
  },
}
</script>

<style lang='less' scoped>
.kaoshi {
  position: relative;
  > div {
    width: 90%;
  }
  .msg {
    margin: 40px 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h4, div {
      color: @default;
    }
  }
  p {
    width: 90%;
    word-break: break-all;
    font-weight: 400;
    line-height: 25px;
  }
  .xuanze {
    > div {
      display: flex;
      align-items: center;
      margin-top: 20px;
      cursor: pointer;
      width: fit-content;
      div {
        border: 1px solid #d8d8d8;
        width: 25px;
        height: 25px;
        line-height: 25px;
        margin-right: 10px;
        text-align: center;
        border-radius: 50%;
        background-color: #fff;
      }
      .xuanzhong {
        border-color: @default;
        color: #fff;
        background-color: @default;
      }
    }
  }
  .btn {
    margin: 120px 0 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .popup {
    width: 100%;
    height: 100%;
    background-color: rgba(000, 000, 000, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    z-index: 999;
    > div {
      margin-top: 5%;
      background-color: #ffffff;
      border-radius: 5px;
      width: fit-content;
      height: fit-content;
      padding: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        width: 100px;
      }
      .hint {
        margin: 20px 0;
      }
      .grade {
        margin-bottom: 20px;
        span {
          font-size: 18px;
        }
      }
      .answer {
        width: 80%;
        margin: 0 0 20px;
        border: 1px dashed #888;
        padding: 15px 15px 30px;
        > div {
          margin: 10px 10px 0;
        }
      }
    }
    .fail {
      color: red;
    }
    .pass {
      color: #00a300;
    }
  }
}
</style>