import request from '../utils/request'

export default {
  getSumCourseArr: (data) => {
    return request({
      url: 'Home/Apinew/kc_xk_list',
      method: 'post',
      data: data
    })
  },
  getCourseList: (data) => {
    return request({
      url: 'Home/Apinew/get_all_kecheng',
      method: 'post',
      data: data
    })
  },
  courseStatus: (data) => {
    return request({
      url: 'Home/Apinew/mykc_num',
      method: 'post',
      data: data
    })
  },
  getRoot: (data) => {
    return request({
      url: 'Home/Apiks/kszg',
      method: 'post',
      data: data
    })
  },
  examhy: (data) => {
    return request({
      url: 'Home/Apiks/get_exam',
      method: 'post',
      data: data
    })
  },
  examzp: (data) => {
    return request({
      url: 'Home/Apiks/upload_zhuapai',
      method: 'post',
      data: data
    })
  },
  studyhy: (data) => {
    return request({
      url: 'Home/Apinew/heyan',
      method: 'post',
      data: data
    })
  },
  studyzp: (data) => {
    return request({
      url: 'Home/Apinew/upload_zhuapai',
      method: 'post',
      data: data
    })
  },
  getTopic: (data) => {
    return request({
      url: 'Home/Apiks/get_moni',
      method: 'post',
      data: data
    })
  },
  answerFun: (data) => {
    return request({
      url: 'Home/Apiks/huida',
      method: 'post',
      data: data
    })
  },
  submitRoll: (data) => {
    return request({
      url: 'Home/Apiks/tijiao',
      method: 'post',
      data: data
    })
  },
  getLianxi: (data) => {
    return request({
      url: 'Home/Apinew/course_qst',
      method: 'post',
      data: data
    })
  },
  getAdmin: (data) => {
    return request({
      url: 'Home/Apinew/check_bofang',
      method: 'post',
      data: data
    })
  },
  getVideo: (data) => {
    return request({
      url: 'Home/Apinew/check_tuodong',
      method: 'post',
      data: data
    })
  },
  savePlayPlan: (data) => {
    return request({
      url: 'Home/Apinew/jindu',
      method: 'post',
      data: data
    })
  },
  playOver: (data) => {
    return request({
      url: 'Home/Apinew/wanbi',
      method: 'post',
      data: data
    })
  },
  buyCourse: (data) => {
    return request({
      url: 'Home/Apinew/add_order',
      method: 'post',
      data: data
    })
  },
  buyResult: (data) => {
    return request({
      url: 'Home/Apinew/check_fukuan',
      method: 'post',
      data: data
    })
  },
  coursejilu: (data) => {
    return request({
      url: 'Home/Apinew/synchro_coursejilu',
      method: 'post',
      data: data
    })
  },
  payCourse: (data) => {
    return request({
      url: 'Home/Apinew/yue_pay',
      method: 'post',
      data: data
    })
  },
  getExamRoot: (data) => {
    return request({
      url: 'Home/ApiRenew/action_exam',
      method: 'post',
      data: data
    })
  },
  getEaxmArr: (data) => {
    return request({
      url: 'Home/ApiRenew/get_exam_tm',
      method: 'post',
      data: data
    })
  },
  examOver: (data) => {
    return request({
      url: 'Home/ApiRenew/huida',
      method: 'post',
      data: data
    })
  },
  examVerify: (data) => {
    return request({
      url: 'Home/ApiRenew/ks_zhuapai',
      method: 'post',
      data: data
    })
  },
  getExamRes: (data) => {
    return request({
      url: 'Home/ApiRenew/dawan',
      method: 'post',
      data: data
    })
  },
  commentFun: (data) => {
    return request({
      url: 'Home/Apinew/push_comment',
      method: 'post',
      data: data
    })
  },
  solveFun: (data) => {
    return request({
      url: 'Home/Apinew/push_doubt',
      method: 'post',
      data: data
    })
  },
  getSolveList: (data) => {
    return request({
      url: 'Home/Apinew/doubt_list',
      method: 'post',
      data: data
    })
  },
}
