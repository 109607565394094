<!--  -->
<template>
  <div class='video-page'>
    <el-dialog
      width="31%"
      top="12vh"
      :visible.sync="show"
      :close-on-click-modal="close"
      :close-on-press-escape="close"
      :show-close="close"
      :destroy-on-close="true"
      @close="onClose"
      center>
      <div v-loading="loading">
        <div class="zhuapai-view">
          <h3 v-if="type == 'sphy' || type == 'kshy'">人脸识别</h3>
          <h3 v-if="type == 'kszp'">考试抓拍</h3>
          <h3 v-if="type == 'spzp'">视频抓拍</h3>
          <h3 v-if="type == 'cszp'">考试抓拍</h3>
          <!-- <p>温馨提示：请将摄像头对准本人脸部，否则以作弊处理</p> -->
          <p>为确保您的{{type | text}}有效性，需要您进行人脸识别，识别通过后才可正常{{type | text}}。</p>
          <div class="photo">
            <span v-if="countDown">{{time}}</span>
            <video id="video"></video>
            <canvas id="canvas"></canvas>
          </div>
        </div>
        <div class="btn">
          <el-button round type="success" class="self-button--success" size="medium" style="width: 160px" @click="handlePat" v-if="!countDown">点击识别</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Api from '../../../api/study'

export default {
  components: {},
  model: {
    prop: 'visible',
    event: 'input'
  },
  props: {
    type: String,
    visible: Boolean,
    id: String || Number,
    name: String,
  },
  data() {
    return {
      show: false,
      loading: true,
      close: false,
      countDown: false,
      time: 5,
      dateTime: '',
      have: true
    };
  },
  filters: {
    text (value) {
      if (value == 'kszp' || value == 'kshy') {
        return '考试'
      } else {
        return '学习'
      }
    }
  },
  computed: {},
  watch: {
    visible () {
      this.show = this.visible
      if (this.show) {this.init()}
    },
  },
  created() {
    
  },
  mounted() {
    
  },
  beforeDestroy () {
    clearInterval(this.dateTime)
    var video = document.getElementById('video');
    if (video && video.srcObject) {
      let stream = video.srcObject
      let tracks = stream.getTracks();
      tracks.forEach(track => {
        track.stop()
      })
    }
  },
  //方法集合
  methods: {
    onClose () {
      var video = document.getElementById('video');
      if (video && video.srcObject) {
        let stream = video.srcObject
        let tracks = stream.getTracks();
        tracks.forEach(track => {
          track.stop()
        })
      }
      clearInterval(this.dateTime)
      this.show = false
      this.$emit('input', this.show)
    },
    init () {
      let that = this
      if (that.type == 'kshy' || that.type == 'sphy') {
        that.close = true
      } else {
        that.close = false
      }
      setTimeout(() => {
        navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;
        if (navigator.getUserMedia) {
          navigator.getUserMedia(
            {
              video: true,
              audio: false,
            },
            function onSuccess (stream) {
              setTimeout(() => {
                var video = document.getElementById("video");
                video.srcObject = stream;
                video.onloadedmetadata = function () {
                  video.play();
                  that.loading = false
                  if (that.type == 'kszp' || that.type == 'spzp' || that.type == 'cszp') {
                    that.countDown = true
                    that.dateTime = setInterval(() => {
                      that.time--
                      if (!that.have) {
                        clearInterval(that.dateTime)
                        that.countDown = false
                        that.time = 5
                        that.$message.error("电脑需安装或自带摄像头，如没有请进入小程序使用手机学习。")
                        return;
                      }
                      if (that.time == 0) {
                        that.countDown = false
                        that.time = 5
                        that.handlePat()
                        clearInterval(that.dateTime)
                      }
                    }, 1000)
                  }
                };
              }, 200)
            },
            function onError () {
              that.have = false
              that.loading = false
            }
          )
        } else {
          that.have = false
          that.loading = false
        }
      }, 200)
    },
    handlePat () {
      if (!this.have) {
        this.$message.error("电脑需安装或自带摄像头，如没有请进入小程序使用手机学习。")
        return;
      }
      this.loading = true
      var canvas = document.getElementById("canvas");
      var video = document.getElementById("video");
      canvas.width = 500;
      canvas.height = 500;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(video, 0, 0, 500, 500);
      var base64 = canvas.toDataURL("images/png");

      if (this.type == 'kshy') {
        this.examhy(base64)
      }
      if (this.type == 'kszp') {
        this.examzp(base64)
      }
      if (this.type == 'sphy') {
        this.studyhy(base64)
      }
      if (this.type == 'spzp') {
        this.studyzp(base64)
      }
      if (this.type == 'cszp') {
        this.testzp(base64)
      }
    },
    // 考试核验
    examhy (base64) {
      Api.examhy({
        file: base64,
        model: 'pc',
        system: ''
      }).then(res => {
        this.loading = false
        if (res.data.code == 1) {
          this.show = false
          this.$router.push({name: 'Kaoshi', query: {id: res.data.exam_id}})
        } else {
          if (res.data.msg == "活体检测不通过") {
            this.$message.error('活体检测不通过')
          } else {
            this.$message.error('请确定是本人后重试，个人信息更换本人头像照片，如还不能通过人脸识别，加微信：17375720927。')
          }
        }
      })
    },
    // 考试抓拍
    examzp (base64) {
      Api.examzp({
        file: base64,
        exam_id: this.id
      }).then(res => {
        this.loading = false
        if (res.data.code == 1) {
          this.show = false
        } else {
          if (res.data.msg == "活体检测不通过") {
            this.$message.error('活体检测不通过')
          } else {
            this.$message.error('请确定是本人后重试，个人信息更换本人头像照片，如还不能通过人脸识别，加微信：17375720927。')
          }
        }
      })
    },
    // 考试抓拍
    testzp (base64) {
      Api.examVerify({
        file: base64,
        exam_id: this.id
      }).then(res => {
        this.loading = false
        if (res.data.code == 1) {
          this.show = false
        } else {
          if (res.data.msg == "活体检测不通过") {
            this.$message.error('活体检测不通过')
          } else {
            this.$message.error('请确定是本人后重试，个人信息更换本人头像照片，如还不能通过人脸识别，加微信：17375720927。')
          }
        }
      })
    },
    // 视频学习核验
    studyhy (base64) {
      Api.studyhy({
        file: base64,
      }).then(res => {
        this.loading = false
        if (res.data.xsd == '通过') {
          this.show = false
          this.$emit('handlePass')
        } else {
          if (res.data.xsd == "活体检测不通过") {
            this.$message.error('活体检测不通过')
          } else {
            this.$message.error('请确定是本人后重试，个人信息更换本人头像照片，如还不能通过人脸识别，加微信：17375720927。')
          }
        }
      })
    },
    // 视频学习抓拍
    studyzp (base64) {
      Api.studyzp({
        file: base64,
        keshi_name: this.name,
        vid: this.id
      }).then(res => {
        this.loading = false
        if (res.data.code == 1) {
          this.show = false
          this.$emit('hide')
        } else {
          if (res.data.msg == "活体检测不通过") {
            this.$message.error('活体检测不通过')
          } else {
            this.$message.error('请确定是本人后重试，个人信息更换本人头像照片，如还不能通过人脸识别，加微信：17375720927。')
          }
        }
      })
    }
  },
}
</script>

<style lang='less' scoped>
.video-page {
  .zhuapai-view {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      color: #DD011B;
      margin: 20px 0 40px;
    }
    .photo {
      width: 300px;
      height: 300px;
      background-color: #EDF6FF;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      border-radius: 50%;
      overflow: hidden;
      span {
        font-size: 150px;
        font-weight: bold;
        color: @default;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      #video {
        height: 300px;
      }
      #canvas {
        display: none;
        position: absolute;
        z-index: 200;
        width: 100%;
        height: 100%;
        background: #fff;
      }
    }
  }
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
}
</style>